var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4  px-5"},[_c('h2',[_vm._v("Vereine")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.comp_Clubs,"sort-by":"nummer"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.initialize}},[_c('v-icon',[_vm._v("mdi-reload")])],1)],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [(item.created_at)?_c('span',[_vm._v(_vm._s(new Date(item.created_at.replace(" ", "T")).toLocaleString()))]):_vm._e()]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [(item.updated_at)?_c('span',[_vm._v(_vm._s(new Date(item.updated_at.replace(" ", "T")).toLocaleString()))]):_vm._e()]}},{key:"item.LastLogin",fn:function(ref){
var item = ref.item;
return [(item.LastLogin)?_c('span',[_vm._v(_vm._s(new Date(item.LastLogin.replace(" ", "T")).toLocaleString()))]):_vm._e()]}},{key:"item.LasLogout",fn:function(ref){
var item = ref.item;
return [(item.LasLogout)?_c('span',[_vm._v(_vm._s(new Date(item.LasLogout.replace(" ", "T")).toLocaleString()))]):_vm._e()]}},{key:"item.can_glt",fn:function(ref){
var item = ref.item;
return [(item.can_glt !== 0)?_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"color":"success"}},[_vm._v(" mdi-check ")]):_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"color":"red"}},[_vm._v(" mdi-window-close ")])]}},{key:"item.can_rfid",fn:function(ref){
var item = ref.item;
return [(item.can_rfid !== 0)?_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"color":"success"}},[_vm._v(" mdi-check ")]):_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"color":"red"}},[_vm._v(" mdi-window-close ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }