<template>
  <div class="mt-4  px-5">

    <h2>Vereine</h2>

    <v-data-table
        :headers="headers"
        :items="comp_Clubs"
        sort-by="nummer"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-spacer></v-spacer>
          <v-btn icon
                 @click="initialize">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.created_at="{ item }">
        <span v-if="item.created_at">{{ new Date(item.created_at.replace(" ", "T")).toLocaleString() }}</span>
      </template>

      <template v-slot:item.updated_at="{ item }">
        <span v-if="item.updated_at">{{ new Date(item.updated_at.replace(" ", "T")).toLocaleString() }}</span>
      </template>

      <template v-slot:item.LastLogin="{ item }">
        <span v-if="item.LastLogin">{{ new Date(item.LastLogin.replace(" ", "T")).toLocaleString() }}</span>
      </template>

      <template v-slot:item.LasLogout="{ item }">
        <span v-if="item.LasLogout">{{ new Date(item.LasLogout.replace(" ", "T")).toLocaleString() }}</span>
      </template>

      <template v-slot:item.can_glt="{ item }">
        <v-icon
            v-if="item.can_glt !== 0"
            class="d-flex justify-center"
            color="success">
          mdi-check
        </v-icon>
        <v-icon
            v-else
            class="d-flex justify-center"
            color="red">
          mdi-window-close
        </v-icon>
      </template>

      <template v-slot:item.can_rfid="{ item }">
        <v-icon
            v-if="item.can_rfid !== 0"
            class="d-flex justify-center"
            color="success">
          mdi-check
        </v-icon>
        <v-icon
            v-else
            class="d-flex justify-center"
            color="red">
          mdi-window-close
        </v-icon>
      </template>

      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Clubs',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        align: 'Kurz',
        sortable: false,
      },
      {text: 'Nummer', value: 'Vereinsnummer'},
      {text: 'Name', value: 'name'},
      {text: 'Ort', value: 'ort'},
      {text: 'PLZ', value: 'PLZ'},
      {text: 'Strasse', value: 'strasse'},
      {text: 'E-Mail', value: 'email'},
      {text: 'Einträge Schießbuch', value: 'shootingbooks_count'},
      {text: 'GLT', value: 'can_glt'},
      {text: 'RFID', value: 'can_rfid'},
      {text: 'Erstellt', value: 'created_at'},
      {text: 'Geändert', value: 'updated_at'},
      {text: 'letzte Anmeldung', value: 'LastLogin'},
      {text: 'letzte Abmeldung', value: 'LasLogout'},
    ],

    editedItem: [],
  }),
  computed: {
    comp_Clubs: {
      get() {
        return this.$store.state.AdminApp.Clubs;
      }
    },
  },
  mounted() {
    this.getClubs();
  },
  methods: {

    getClubs() {
      this.$store.dispatch('AdminApp/getClubsData');
    },
    editItem(item) {
      console.log(item);
    },
    initialize() {
      this.getClubs();
    },
  },
}
</script>

<style scoped>

</style>